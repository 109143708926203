<template>
    <div id="AdminMemberIndex"><router-view></router-view></div>
</template>
<script>
export default {
    name: 'AdminMemberIndex',
}
</script>
<script setup></script>
<style lang="scss" scoped></style>
